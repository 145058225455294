import { BlockComponent } from "framework/src/BlockComponent";
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history: any;
  location: any;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  isModal:boolean;
  fullName: string;
  email: string;
  gender: string;
  relation:string;
  bloodGroup:string;
  dateOfBirth:string;
  weight: string;
  bloodGroup_error: string;
  fullName_error: string;
  gender_error: string;
  dateOfBirth_error: string;
  weight_error: string;
  relation_error: string;
  GenderData:string[];
  relationData:string[];
  bloodGroupData:string[];
  setImage: string
  // Customizable Area End
}

export interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProfileSwitchAccountPageController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.state = {
    // Customizable Area Start
    isModal:false,
    fullName: "",
      email: "",
      gender: "Select",
      relation:"Select",
      bloodGroup:"Select",
      dateOfBirth:"",
      weight: "",
      bloodGroup_error: '',
      fullName_error: "",
      gender_error: "",
      dateOfBirth_error: "",
      weight_error: "",
      relation_error: "",
      GenderData:["Male", "Female"],
      relationData:["GrandFather", "GrandMother","Father", "Mother","Brother", "Sister", "Child"],
      bloodGroupData:["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"],
      setImage:""
      // Customizable Area End
    };
  }

  // Customizable Area Start
  handleMemberDetailsOpen = () => {
    this.props.history.push("/ProfileMemberDetailsPage");
  }
  handleEditIcon = () => {
    this.props.history.push("/ProfileMemberDetailsPage");
  }
  handleDeleteBtn = () => {
    this.setState({isModal:true})
  }
  handleBack = () => {
    this.setState({isModal:false})
  }
  handleChangeFullName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fullName = event.target.value;
    const alphaFullName = fullName.replace(/[^a-zA-Z ]/g, "");
    this.setState({ fullName: alphaFullName, fullName_error:"" });
  };

  handleChangeGender = (value:string) => {
    this.setState({ gender: value, gender_error:""});
  };
  handleChangeRelation = (value:string) => {
    this.setState({ relation: value, relation_error:"" });
  };
  handleChangeBloodGroup = (value:string) => {
    this.setState({ bloodGroup: value, bloodGroup_error:"" });
  };
  handleChangeDateOfBirth = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({ dateOfBirth: event.target.value , dateOfBirth_error:""});
  };
  handleChangeWeight = (event: React.ChangeEvent<HTMLInputElement>): void => {
    let value = event.target.value.replace(/[^\d]/g, "");
    this.setState({ weight: value, weight_error:'' });
  };
  handleGoToSwitchPage = () => {
        this.props.history.push("/ProfileSwitchAccountPage");
  }
  validate = () => {
    const { fullName, gender, dateOfBirth, weight, relation, bloodGroup} = this.state;

    if (fullName === "") {
      this.setState({ fullName_error: "Please Enter Your full Name" });
    }
    if (gender === "Select") {
      this.setState({ gender_error: "Please Select Gender" });
    }
    if (dateOfBirth === "") {
      this.setState({ dateOfBirth_error: "Please Enter Date of Birth" });
    }
    if (weight === "") {
      this.setState({ weight_error: "Enter Your weight" });
    }
    if (relation === "Select") {
        this.setState({ relation_error: "Select relation" });
      }
    if (bloodGroup === "Select") {
        this.setState({ bloodGroup_error: "Select blood group" });
      }
    else{
        alert("Member added Sucessfully")
    }
  };
  handleSubmit = async () => {
    this.validate();
  };
  handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      this.setState({setImage:imageUrl})
    }
  };
  
  // Customizable Area End
}
