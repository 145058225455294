import { notification } from "antd";
import { BlockComponent } from "framework/src/BlockComponent";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history: any;
  location: any;
  selectedKey: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  selectedKey: string;
  showPopup: boolean;
  isLoginUser: boolean;
  patientDetails: any;

  // Customizable Area End
}

export interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProfilePageOutLet extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.state = {
      // Customizable Area Start
      selectedKey: "",
      showPopup: false,
      isLoginUser: true,
      patientDetails: null,

      // Customizable Area End
    };
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    const patientDetails = localStorage.getItem("patientDetails");
    if (patientDetails !== null) {
      this.setState({ patientDetails: JSON.parse(patientDetails) });
    }
    this.setState({ selectedKey: this.props.selectedKey });

    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area End

  // Customizable Area Start
  handleMenuClick = (e: any) => {
    this.setState({ selectedKey: e.key });
  };

  handleNavigateToInsurance = () => {
    this.props.history.push({ pathname: "/FeatureComingSoon", state: { selectedN: "2" } })
  };

  handleNavigateToPayment = () => {
    this.props.history.push({ pathname: "/FeatureComingSoon", state: { selectedN: "4" } })
   
  };

  handleNavigateToSwitchAccount = () => {
    this.props.history.push({ pathname: "/ProfileSwitchAccountPage", state: { selectedN: "8" } })
  };

  handleNavigateToHealthRecords = () => {
    this.props.history.push({ pathname: "/FeatureComingSoon", state: { selectedN: "1" } })
  };

  handleNavigateToAddress = () => {
    this.props.history.push("/PatientManageAddressPage");
  };
  handleNavigateToSettings = () => {
    this.props.history.push("/ProfileSettingsPage");
  };
  handleNavigateToContactUs = () => {
    this.props.history.push("/ProfileContactUsPage");
  };
  handleNavigateToOrder = () => {
    this.props.history.push("/PatientOrder");
  };
  handleNavigateToAppointment = () => {
    this.props.history.push("/PatientAppointmentsPage");
  };
  handleLog = () => {
    localStorage.removeItem("token");
    this.props.history.push("/");
  };
  showDetailsHandler = () => {
    this.setState({ showPopup: !this.state.showPopup });
  };

  handleLogout = () => {
    localStorage.clear();
    notification["success"]({
      message: "Logout successfully",
    });
  };

  // Customizable Area End
}
