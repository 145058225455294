import React from "react";
// Customizable Area Start
import ProfileSwitchAccountPageController from "./ProfileSwitchAccountPageController.web";
import PatientOutLet from "./ProfileOutlet.web";
import { Typography, Divider} from "antd";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { MdEdit } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import {defaultImg} from './assets';
import { Modal, styled, Box } from '@material-ui/core';

// Customizable Area End

class ProfileSwitchAccountPage extends ProfileSwitchAccountPageController {
    // Customizable Area Start
    render() {
        const accountDetails = [
            { name: "John Dew", email: "john@gmail.com", healthId: "#89897" },
            { name: "Rocky Danish", email: "rocky@gmail.com", healthId: "#89547" }]
        return (
            <div>
                <PatientOutLet {...this.props} selectedKey={"5"}>
                    <h3 className="Settingheading">Switch Account</h3>
                    {accountDetails.map((data, index) => {
                        return (
                            <div key={index}>
                                <div className="showAccountDetails">
                                    <div className="details">
                                        <img src={defaultImg} alt="Selected"
                                          className="showProfileImage"/>
                                          <div>
                                        <h4>{data.name}</h4>
                                        <Typography>{data.email}</Typography>
                                        <Typography>Health Id: {data.healthId}</Typography>
                                        </div>
                                    </div>
                                    <div className="iconList">
                                        <CheckCircleIcon className="checkIcon" />
                                        <MdEdit style={{ color: "#3a78bf", fontSize: "18px", }} onClick={this.handleEditIcon} data-test-id="handleEditIcon"/>
                                                <div
                                style={{ cursor:"pointer"}}
                                onClick={this.handleDeleteBtn}
                                data-test-id="handleDeleteBtn"
                                >
                                <RiDeleteBinLine style={{ color: "#3a78bf", fontSize: "18px", }} />
                                </div>
                                    </div>
                                </div>
                                <Divider />
                            </div>
                        )
                    })}

                    <Typography className='addNewBtn' data-test-id="handleMemberDetailsOpen" onClick={this.handleMemberDetailsOpen}><AddCircleIcon style={{ marginRight: "20px", fontSize: "25px" }} /> <span>Add New Account</span></Typography>
                    <DeleteAppointmentModal
                    data-test-id="handleBack"
                    open={this.state.isModal}
                    onClose={this.handleBack}
                >
                    <ModalComponent>
                        <Box component={"h4"} data-test-id="deletetextid">Delete</Box>
                        <Box component={"p"}>
                            Are you sure you want to delete the appointment with the patient?
                        </Box>
                        <ButtonBox>
                            <NoButton onClick={this.handleBack} data-test-id="closemodalid">No</NoButton>
                            <YesButton onClick={this.handleBack}>Yes, I'm sure</YesButton>
                        </ButtonBox>
                    </ModalComponent>
                </DeleteAppointmentModal>
                </PatientOutLet>
            </div>
        );
    }

    // Customizable Area End
}

// Customizable Area Start
export default ProfileSwitchAccountPage;
const DeleteAppointmentModal = styled(Modal)({
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
});

const ModalComponent = styled(Box)({
    textAlign: "center",
    padding: "50px 100px",
    borderRadius: '5px',
    backgroundColor: '#ffffff',
    outline: "none",
    margin: "20px",
    "@media (max-width:800px)": {
        padding: "10px 50px",
    },
    "@media (max-width:400px)": {
        padding: "10px 20px",
    }
});
const YesButton = styled("button")({
    backgroundColor: "#3b78c2",
    color: "white",
    borderRadius: "25px",
    border: "2px solid #3b78c2",
    padding: "10px",
    width: "140px",
    fontWeight: "bold",
    cursor: "pointer",
    "@media (max-width:800px)": {
        fontSize: "13px",
        width: "130px"
    }
});
const ButtonBox = styled(Box)({
    display: "flex",
    justifyContent: "center",
    margin: "10px 0px",
    columnGap: "10px"
});

const NoButton = styled("button")({
    color: "#3b78c2",
    border: "2px solid #3b78c2",
    borderRadius: "25px",
    padding: "10px",
    width: "140px",
    backgroundColor: "#ffffff",
    fontWeight: "bold",
    cursor: "pointer",
    "@media (max-width:800px)": {
        fontSize: "13px",
        width: "130px"
    }
});

// Customizable Area End
