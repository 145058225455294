import React from "react";
// Customizable Area Start
import { Button, Input, Typography, Select } from "antd";
import PatientOutLet from "./ProfileOutlet.web";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ProfileSwitchAccountPageController from "./ProfileSwitchAccountPageController.web";
import {defaultImg} from './assets';
import { FaCamera } from "react-icons/fa";
// Customizable Area End
class ProfileMemberDetailsPage extends ProfileSwitchAccountPageController {
    // Customizable Area Start
    render() {
        return (
            <div>
                <PatientOutLet {...this.props} selectedKey={"6"}>
                    <form
                        style={{ display: "flex", flexDirection: "column", gap: "15px" }}
                    >
                        <Typography className="addDetails"> <ArrowBackIosIcon onClick={this.handleGoToSwitchPage} style={{cursor:'pointer'}} data-test-id="handleGoToSwitchPage"/> Add Patient Details</Typography>
                        <div className="profileStyle">
      <img
        src={this.state.setImage ? this.state.setImage: defaultImg}
        alt="Selected"
        className="showImage"
      />
      <label
        htmlFor="fileInput"
        className="cameraStyle"
      >
        <FaCamera size={12} color="white"/>
      </label>
      <input
        id="fileInput"
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        data-test-id="handleImageUpload"
        onChange={this.handleImageUpload}
      />
    </div>
                        <div>
                            <label className="text-label-css-input">Full Name</label>
                            <Input
                                className="text-input-css"
                                name="fullName"
                                value={this.state.fullName}
                                data-test-id={"handleChangeFullName"}
                                onChange={this.handleChangeFullName}
                            />
                            {this.state.fullName_error !== "" &&
                                this.state.fullName === "" && (
                                    <p style={{ color: "red" }}>{this.state.fullName_error}</p>
                                )}
                        </div>
                        <div className="selectItem">
                            <label className="text-label-css-input">Gender</label>
                            <Select
                                data-test-id='handleChangeGender'
                                defaultValue={this.state.gender}
                                placeholder="Please select login role"
                                style={{ width: "100%" }}
                                onChange={this.handleChangeGender}
                            >
                                {this.state.GenderData.map((item, index) => (
                                    <Select.Option value={item} key={index} name="btnSelectRole">
                                        {item}
                                    </Select.Option>
                                ))}
                            </Select>

                            {this.state.gender_error !== "" &&
                                    <p style={{ color: "red" }}>{this.state.gender_error}</p>
                                }
                        </div>
                        <div className="selectItem">
                            <label className="text-label-css-input">Relation</label>
                            <Select
                                data-test-id='handleChangeRelation'
                                defaultValue={this.state.relation}
                                placeholder="Please select login role"
                                style={{ width: "100%" }}
                                onChange={this.handleChangeRelation}
                            >
                                {this.state.relationData.map((item, index) => (
                                    <Select.Option value={item} key={index} name="btnSelectRole">
                                        {item}
                                    </Select.Option>
                                ))}
                            </Select>

                            {this.state.relation_error !== "" &&
                                    <p style={{ color: "red" }}>{this.state.relation_error}</p>
                                }
                        </div>
                        <div className="selectItem">
                        <label className="text-label-css-input">Date of Birth (DD-MM-YY)</label>
                            <Input
                                id="dateOfBirth"
                                className="text-input-css"
                                name="dateOfBirth"
                                value={this.state.dateOfBirth}
                                onChange={this.handleChangeDateOfBirth}
                                type="date"
                                data-test-id="handleChangeDateOfBirth"
                            />
                            {this.state.dateOfBirth_error !== "" &&
                                    <p style={{ color: "red" }}>{this.state.dateOfBirth_error}</p>
                                }
                            
                        </div>
                        <div className="dobSection">
                        <div className="profileSection">
                            <label className="text-label-css-input">Weight</label>
                            <Input
                                className="text-input-css"
                                name="weight"
                                value={this.state.weight}
                                data-test-id={"handleChangeWeight"}
                                onChange={this.handleChangeWeight}
                                inputMode="numeric" 
                                pattern="[0-9]*"
                                placeholder="Enter weight in kg"
                            />
                            {this.state.weight_error !== "" &&
                                this.state.weight === "" && (
                                    <p style={{ color: "red" }}>{this.state.weight_error}</p>
                                )}
                        </div>
                        <div className="profileSection">
                        <label className="text-label-css-input">Blood Group</label>
                            <Select
                                data-test-id='handleChangeBloodGroup'
                                defaultValue={this.state.bloodGroup}
                                style={{ width: "100%" }}
                                onChange={this.handleChangeBloodGroup}
                            >
                                {this.state.bloodGroupData.map((item, index) => (
                                    <Select.Option value={item} key={index} name="btnSelectRole">
                                        {item}
                                    </Select.Option>
                                ))}
                            </Select>

                            {this.state.bloodGroup_error !== "" &&
                                    <p style={{ color: "red" }}>{this.state.bloodGroup_error}</p>
                                }
                        </div>
                        
                        </div>
                        <Button
                            className="submit-btn-form"
                            data-test-id={"submitId"}
                          onClick={this.handleSubmit}
                        >
                            Add member
                        </Button>
                    </form>
                </PatientOutLet>
            </div>
        );
    }
    // Customizable Area End
}
// Customizable Area Start
export default ProfileMemberDetailsPage;
// Customizable Area End
