Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "dataencryption";
exports.labelBodyText = "dataencryption Body";

exports.getMyOrderAPiEndPoint = "bx_block_order_management/my_orders";
exports.getMyOrderAPiMethod = "GET";
exports.getMyOrderApiContentType = "application/json";
exports.getProfileDetailsContentType = "application/json";

exports.getAddressAPiEndPoint = "bx_block_address/create_address";
exports.getAddressAPiMethod = "POST";
exports.getAddressApiContentType = "application/json";

exports.postContactUsAPiEndPoint = "bx_block_contact_us/contacts";
exports.postContactUsAPiMethod = "POST";
exports.postContactUsApiContentType = "application/json";

exports.getManageAddressAPiEndPoint = "bx_block_address/patient_all_addresses"
exports.getManageAddressAPiMethod = "GET";
exports.getManageAddressApiContentType = "application/json"; 

exports.deleteManageAddressAPiEndPoint = "bx_block_address/delete_address_details"
exports.deleteManageAddressAPiMethod = "DELETE";
exports.deleteManageAddressApiContentType = "application/json";

exports.updateManageAddressAPiEndPoint = "bx_block_address/update_address"
exports.updateManageAddressAPiMethod = "PUT";
exports.updateManageAddressApiContentType = "application/json";

exports.getAppointmentsAPiEndPoint = "bx_block_appointment_management/patient_appointment_list";
exports.getAppointmentsAPiMethod = "GET";
exports.getAppointmentsApiContentType = "application/json";

exports.btnExampleTitle = "CLICK ME";
exports.getUpcomingAppointmentEndPoint="bx_block_appointment_management/upcoming_appointment";
exports.getCanceledAppointmentEndPoint="bx_block_appointment_management/patient_appointment";
exports.cancelAppointmentEndPoint="bx_block_appointment_management/cancel_appointment";
exports.previousAppointmentEndPoint="bx_block_appointment_management/previous_appointment";
exports.getPrescriptionDataEndPoint="bx_block_medicine/show";
exports.downloadPrescriptionEndPoint="bx_block_medicine/generate_prescription_pdf"
// Customizable Area End