import React from "react";
// Customizable Area Start
import PatientAppointmentJoiningPageController from "./PatientAppointmentConfirmationPageController.web";
import { Col, Row, Typography, Avatar,Rate, Form,Card, DatePicker, Segmented } from "antd";
import {Box,Button,styled,Grid,Container, Modal} from "@material-ui/core"
import { calendarimg, clockimg } from "./assets";
import {consultation,discount} from "../../appointmentmanagement/src/assets"
import { Default } from "../../splashscreen/src/assets";
let config = require("../../../framework/src/config.js").baseURL;
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { PiWarningBold,PiSunHorizonBold } from "react-icons/pi";
import {Link} from "react-router-dom" 
import moment from "moment";
import NavbarCommonComponent from "../../../components/src/NavbarCommonComponent.web";
import PatientProfilePopup from "../../../components/src/PatientProfilePopup.web";
// Customizable Area End
class PatientAppointmentJoiningPage extends PatientAppointmentJoiningPageController {
  // Customizable Area Start

  renderNavigationTitle = () => {
    return (
      <>
        <div
          className="navigationContainer"
          style={{ boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.03)" }}
        >
          <Link to="/patienthome">Home</Link>
          <Link to="/patient-appointment-landingpage">Appointments</Link>
          <Link to="/services">Services</Link>
          <Link to="/patientmessage">Messages</Link>
        </div>
      </>
    );
  };

  renderModalTitle = () => {
    let titleContent;
  
    if (this.state.isModalOpen) {
      titleContent = (
        <Box p={0} className="modal--header-container" >
          <PiWarningBold className="warning-icons" /> <span className="model-title" >Feature Coming Soon</span>
        </Box>
      );
    } else if (this.state.isCancellModalOpen) {
      titleContent = <span className="model-title">Cancel Appointment ?</span>;
    } else {
      titleContent = (
        <Box p={0} className="modal--header-container" >
          <PiWarningBold className="warning-icons" /> <span className="model-title" >Reschedule Appointment</span>
        </Box>
      );
    }
  
    return <DialogTitle>{titleContent}</DialogTitle>;
  }
  
  renderJoinConsultationModal = () => {
    return (
        <>
            <JoinConsultationModal
                data-test-id="joinConsultationModalId"
                open={this.state.isJoinConsultation}
                onClose={this.handleJoinConsultationModal}
            >
                <JoinModalComponent>
                    <TextBegin component={"h4"} data-test-id="begintextid">Begin the consultation</TextBegin>
                    <CallBegin component={"p"}>
                        The call will be connected with your <br/>patient. Join it via.
                    </CallBegin>
                    <ButtonBox>
                        <JoinButton disabled={this.state.consultation_type === "Video Consultation" || this.state.consultation_type === "Text Consultation"} className={this.state.consultation_type === "Video Consultation" || this.state.consultation_type === "Text Consultation" ? "disableStyle" : "enableStyle"} onClick={this.handleBeginConsultation} data-test-id="phoneId">Join via Audio</JoinButton>
                        <JoinButton onClick={this.handleBeginConsultation} disabled={this.state.consultation_type === "Phone Consultation" || this.state.consultation_type === "Text Consultation"} data-test-id="beginConsultationId" className={this.state.consultation_type === "Phone Consultation" || this.state.consultation_type === "Text Consultation" ? "disableStyle" : "enableStyle"}>Join via Video</JoinButton>
                    </ButtonBox>
                </JoinModalComponent>
            </JoinConsultationModal>
        </>
    )
};
renderConsultationBeginModal = () => {
  const formattedMinutes = this.state.minutes.toString().padStart(2, '0');
  const formattedSeconds = this.state.seconds.toString().padStart(2, '0');
      return (
          <>
              <JoinConsultationModal
                  open={this.state.isBeginConsultation}
                  data-test-id="beginModalId"
                  onClose={this.handleConsultationBeginModal}
              >
                  <BeginModalComponent>
                      <TextBegin component={"h4"} data-test-id="textid">Consultation will begin in</TextBegin>
                      <CallBegin component={"p"}>
                         {formattedMinutes}: {formattedSeconds}
                      </CallBegin>
                      <ButtonBox>
                      <YesButton onClick={this.handleConsultationBeginModal}>Close</YesButton>
                      </ButtonBox>
                  </BeginModalComponent>
              </JoinConsultationModal>
          </>
      )
  };
  renderModalContentText = () => {
    let contentText;
  
    if (this.state.isModalOpen) {
      contentText = (
        ''
      );
    } else if (this.state.isCancellModalOpen) {
      contentText = (
        <Box textAlign="center" className="model-description">Are you sure you want to cancel the appointment</Box>
      );
    } 
    return <DialogContentText>{contentText}</DialogContentText>;
  }
  renderTimeSlotCard=(timeSlot:any,index:any,testId:string)=>{
    return (
      <Card 
         data-test-id='timeSlot'
         onClick={()=> timeSlot.is_available ? this.handleSetTimeSlot(timeSlot.start_time,index) : {} }  
         className={timeSlot.is_available ? `time_slot_label ${this.state.selectedTimeSlotMorning === index && "moringActiveTimeSlot" }` :"time_slot_label_disable"  }>
            {timeSlot?.start_time}
      </Card> 
  
    )
  }
  options=
    [
      {
        label: (
          <div style={{ padding: 4 }} className="time-slot-container" >
            <Avatar src= {<PiSunHorizonBold style={{color:"black"}}/>} alt="" className="time-slot-img" style={{fontSize:'30px'}}/>
            <div>Morning</div>
          </div>
        ),
        value: 'Morning'
      },
      {
        label: (
          <div style={{ padding: 4 }} className="time-slot-container">
            {/* <Avatar src= {sunnyDay} alt="" className="time-slot-img" /> */}
            <div>Afternoon</div>
          </div>
        ),
        value: 'Afternoon'
      },
      {
        label: (
          <div style={{ padding: 4}} className="time-slot-container">
            {/* <Avatar src= {moon} alt="" className="time-slot-img"/> */}
            <div>Evening</div>
          </div>
        ),
        value:'Evening'
      },

    ]

  renderActions = () => {
    if (this.state.isModalOpen) {
      return (
        <DialogActions>
          <ModalOktButton data-test-id="modalCloseOkID" onClick={(event) => this.handleModalClose(event, "abc")} color="primary">
            OK
          </ModalOktButton>
        </DialogActions>
      );
    } else if (this.state.isCancellModalOpen) {
      return (
        <DialogActions>
          <DoNotWantToCancelButton data-test-id="NO_cancel-appointment-button-id" onClick={(event) => this.handleModalClose(event, "Nocncell")}>
            No I don't want to cancel
          </DoNotWantToCancelButton>
          <YesCancelButton data-test-id="cancel-appointment-button-id" onClick={(event) => this.handleCancelAppointment(event, "abc")}>
            Yes, Cancel
          </YesCancelButton>
        </DialogActions>
      );
    } else if (this.state.isrescheduleModalOpen) {
      return (
        <DialogActions className="rescheduleModal" data-test-id="dislogueAction">
          <div className="doctor_consultant_wrapper"  >
              <Form>
                <Form.Item name="selectDate" >
                <Typography className="doctor_consultant_title"> Select Date</Typography>
                <DatePicker
                  defaultValue={moment()} 
                  name="selectDate"
                  data-test-id="selectDateId" 
                  disabledDate={this.disabledPastDate} 
                  className="select_calender customized datePicker"
                  onChange={this.onDateChange}
                  style={{zIndex:2}}
                   />
                </Form.Item> 
              </Form>
            </div>
            <Col xs={12} sm={20} lg={20} >
              <Segmented data-test-id="handleTimeSlotId" onChange={(item)=>this.handleTimeSlotSection(item)}  size="large" options={this.options} className="showSegmented"/>

            <div className="time_slot_container" >
            
               {
                  this.state.active_time_slot_section === "Morning" && this.state.onlineTimeSlot?.length ?  (
                    this.state.onlineTimeSlot.map((onlinemorningtimeSlot:any,index6:any)=>(
                       this.renderTimeSlotCard(onlinemorningtimeSlot,index6,"morningTimeSlotId")
                    ))

                  ) : null
                }


               {
                  this.state.active_time_slot_section === "Afternoon" && this.state.onlineTimeSlot?.length ?  (
                    this.state.onlineTimeSlot.map((onlineafternnontimeSlot:any,index:any)=>(
                      this.renderTimeSlotCard(onlineafternnontimeSlot,index,"afternoonTimeSlotId")
                    ))
                  ) :null
                }


               {
                  this.state.active_time_slot_section === "Evening" && this.state.onlineTimeSlot?.length ? (
                    this.state.onlineTimeSlot.map((onlineEveningtimeSlot:any,index8:any)=>(
                      this.renderTimeSlotCard(onlineEveningtimeSlot,index8,"eveningTimeSlotId")
                    ))
                  ):null
                }
            </div>
            </Col>
            <ButtonContainer item lg={4}>
            <ButtonTag onClick={this.closeModal} data-test-id="clinicCancelbutton">Cancel</ButtonTag>
            <ButtonTagColor data-test-id="clinicSavebuttonId" onClick= {this.handleRescheduleAppointmentApi}>Reschedule</ButtonTagColor>
            </ButtonContainer>
      </DialogActions>
      );
    } else {
      return null;
    }
  }
  

  renderModal = () => {
    return (
      <Dialog
        maxWidth="sm"
        open={this.state.isModalOpen || this.state.isrescheduleModalOpen || this.state.isCancellModalOpen}
        onClose={this.handleModalClose}
      >
       {this.renderModalTitle()}
       {this.renderModalContentText()}
       {this.renderActions()}
      </Dialog>
    )
  }


  render() {
    return (
      <div>
          <NavbarCommonComponent
          handleProfile={this.showDetailsHandler}
          data-test-id="handleshowId"
        />
        <PatientProfilePopup data-test-id="handleLogoutId" profileData={this.state.showPopup} logoutFunctiuon={this.handleLogout} />
        {this.renderModal()}
        {this.renderNavigationTitle()}
        <Container style={{width:"95%", paddingBottom:"24px"}} >
        <Box style={{padding: "16px 0"}} >
            <Row gutter={[24, 24]} >
              <Col xl={3} style={{ display: "flex", flexDirection: "row", alignItems: "center" }} >
                <Avatar style={{ width: "100px", height: "100px" }} size="large" src={ config + this.state.doctor_profile_image || Default} />
              </Col>
              <Col xl={7} >
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} >
                  <Typography className="doctorName">Dr. {this.state.doctor_full_name}</Typography>
                  <Rate allowHalf disabled value={4} />
                </div>
                <Typography className="doctorDescription" style={{ margin: "8px 0" }} >{this.state.doctor_category} </Typography>
                <div className="doctorExperienceWrapper">
                  <Typography >{this.state.doctor_experience} Years Experience </Typography>
                  <Typography>0 reviews </Typography>
                </div>
              </Col>
            </Row>
          </Box>
          <Box pt={2} mb={2}>
            <Typography className="doctorName">Appointments Details</Typography>
          </Box>
          <AppointmentDetyailsBox >
            <Grid container spacing={6}>
              <Grid item xs={12} sm={6}>
              <div className="appointment-details-wrapper" >
                  <div className="phone-consultation-wrapper" >
                    <img className="appoinments-details-img" alt="img1" src={consultation} />
                    <Typography>{this.state.consultation_type || this.state.consultation_category } </Typography>
                  </div>

                  <div className="phone-consultation-wrapper" >
                    <img className="appoinments-details-img" alt="img1" src={calendarimg} />
                    <Typography>{this.state.appointment_date}</Typography>
                  </div>

                  <div className="phone-consultation-wrapper" >
                    <img className="appoinments-details-img" alt="img1" src={clockimg} />
                    <Typography>{this.state.time_slot} </Typography>
                  </div>


                  <div className="phone-consultation-wrapper" >
                    <img className="appoinments-details-img" alt="img1" src={discount} />
                    <Typography>Fees: Rs. {this.state.fees} </Typography>
                  </div>

                </div>
              </Grid>

              <Grid item xs={12} sm={6} >
                <div className="appointments-button-wrapper" >
                  {
                    this.state.consultation_category != "In Clinic Consultation" && (this.state.consultation_type === "Text Consultation") ? null :this.state.consultation_category != "In Clinic Consultation" &&
                    (this.state.isConsultationStart &&  <StartAppointmentButton data-test-id="start_appointment_ID" onClick={() => this.handleJoinModal()} fullWidth >Start Appointment</StartAppointmentButton> )
                   }

                  <RescheduleAppointmentButton data-test-id="reschedule_appointment_ID" onClick={() => this.handleRescheduleAppointments()} fullWidth >Reschedule Appointment</RescheduleAppointmentButton>
                  <CancelAppointmentButton data-test-id="cancel_appointment_ID" onClick={() => this.handleOpenCancelAppointmentModal()} fullWidth  >Cancel Appointment</CancelAppointmentButton>
                </div>
                {this.renderJoinConsultationModal()}
                {this.renderConsultationBeginModal()}
              </Grid>
            </Grid>
          </AppointmentDetyailsBox>
        </Container>
      </div>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default PatientAppointmentJoiningPage;

const BoxContainer= styled(Box)({
  borderRadius:'10px'
})
const StartAppointmentButton=styled(Button)({
  background:"#498ECC",
  backgroundColor:"#498ECC",
  borderRadius:"24px",
  textTransform:"capitalize",
  fontWeight:500,
  maxWidth:"350px",
  fontSize:"18px",
  color:"#ffff",
  '&:hover': {
    backgroundColor: '#498ECC',
  },

})

const RescheduleAppointmentButton=styled(Button)({
  background:"#FBBB2C",
  borderRadius:"24px",
  textTransform:"capitalize",
  fontWeight:500,
  maxWidth:"350px",
  fontSize:"18px",
  color:"#ffff",
  '&:hover': {
    backgroundColor: '#FBBB2C',
  },
})

const CancelAppointmentButton=styled(Button)({
  background:"red",
  borderRadius:"24px",
  textTransform:"capitalize",
  fontWeight:500,
  fontSize:"18px",
  maxWidth:"350px",
  color:"#ffff",
  '&:hover': {
    backgroundColor: 'red',
  },
})

const ModalOktButton=styled(Button)({
  background:"#498ECC",
  borderRadius:"24px",
  textTransform:"capitalize",
  fontWeight:500,
  fontSize:"18px",
  width:"130px",
  color:"#fff",
  '&:hover': {
    backgroundColor: '#498ECC',
  },
})

const  DoNotWantToCancelButton=styled(Button)({
  background:"#fff",
  borderRadius:"24px",
  textTransform:"capitalize",
  fontWeight:500,
  marginLeft:"24px",
  border:"1px solid black",
  fontSize:"16px",
  color:"#000",
  '&:hover': {
    backgroundColor: '#fff',
  },
})


const YesCancelButton=styled(Button)({
  background:"red",
  borderRadius:"24px",
  textTransform:"capitalize",
  fontWeight:500,
  marginLeft:"24px",
  fontSize:"16px",
  color:"#ffff",
  width:"180px",
  '&:hover': {
    backgroundColor: 'red',
  },
})

const AppointmentDetyailsBox= styled(Box)({
  padding:"24px",
  borderRadius:"12px",
  width:"100%",
  boxShadow: " 0px 2px 13px 0px rgba(88, 87, 87, 0.16)",
  marginBottom:"24px",
  paddingBottom:"24px"
})
const JoinConsultationModal = styled(Modal)({
  height: "100%",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
});
const ButtonContainer = styled(Grid)({
  display: "flex",
  alignItems: "center"
});
const ButtonTag = styled("button")({
  display: "flex",
  alignItems: "center",
  width: "120px",
  borderRadius: "20px",
  height: "35px",
  border: "1px solid #8080804a",
  backgroundColor: "white",
  justifyContent: "center",
  marginRight: "10px",
  color: "#535151",
  padding:'20px'
});
const ButtonTagColor = styled("button")({
  display: "flex",
  alignItems: "center",
  width: "120px",
  borderRadius: "20px",
  height: "35px",
  backgroundColor: "#4d96dd",
  justifyContent: "center",
  marginRight: "10px",
  border: "0",
  color: "white",
  padding:'20px 30px'

});
const JoinModalComponent = styled(Box)({
  "@media (max-width:800px)": {
      padding: "10px 50px",
  },
  "@media (max-width:400px)": {
      padding: "10px 20px",
  },
  textAlign: "center",
  padding: "20px 100px",
  borderRadius: '10px',
  backgroundColor: '#ffffff',
  outline: "none",
  margin: "20px",
});

const TextBegin = styled(Box)({
  fontWeight: 700,
  marginBottom: "10px",
  fontSize: "18px"
});

const CallBegin = styled(Box)({
   fontWeight: 500,
   marginBottom: "20px"
});
const ButtonBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  margin: "10px 0px",
  columnGap: "10px"
});

const JoinButton = styled("button")({
   color: "white",
   borderRadius: "25px",
   border: "none",
   padding: "10px",
   width: "180px",
   cursor: "pointer",
   "@media (max-width:800px)": {
       fontSize: "13px",
       width: "130px"
   },
   backgroundColor: "#ffffff",
   fontWeight: "bold",
   "&:hover" : {
       backgroundColor: "#5a8dc7",
       color: "white",
       border: "none",
   },
   "&.enableStyle":{
       backgroundColor: "#3a79c0",
       border: "none",
   },
   "&.disableStyle":{
       backgroundColor: "gray",
       color: "white",
       border: "none",
       cursor: "none"
   }
});
const BeginModalComponent = styled(Box)({
  textAlign: "center",
  padding: "17px 100px",
  borderRadius: '10px',
  "@media (max-width:800px)": {
      padding: "10px 50px",
  },
  "@media (max-width:400px)": {
      padding: "10px 20px",
  },
  backgroundColor: '#ffffff',
  outline: "none",
  margin: "20px",
});
const YesButton = styled("button")({
  backgroundColor: "#3b78c2",
  color: "white",
  borderRadius: "25px",
  border: "2px solid #3b78c2",
  padding: "10px",
  width: "140px",
  fontWeight: "bold",
  cursor: "pointer",
  "@media (max-width:800px)": {
      fontSize: "13px",
      width: "130px"
  }
});

// Customizable Area End