import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import React from "react";
import { message as MESSAGE, notification } from "antd";
import ApiCall from "../../../components/src/ApiCall.web";
import type { DatePickerProps } from 'antd';
import moment from "moment";
import type { FormInstance } from "antd/es/form";
// Customizable Area End

export const configJSON = require("./config");

export type Props = {
  // Customizable Area Start
  id: string;
  history: any;
  location: any;
  // Customizable Area End
}

// Customizable Area Start
export interface SymptomsType {
  id: string,
  type: string,
  attributes: {
    symptom_name: string,
    featured_therapy_id: string,
    symptom_image: string,
    type: string,
  }
}

export interface AppointmentType {
  appointment: {
    data: [
      {
        id: number,
        type: string,
        attributes: {
          doctor_id: string,
          patient_id: string,
          health_id: string,
          time_slot: string,
          mode_type: string,
          doctor_full_name: string,
        }
      },
    ]
  },
}

export interface TimeslotsType {
  data: Array<TimeSlots>
}

export interface TimeSlots {
  start_time: "",
  end_time: "",
  is_available: false
}

export interface SelectSymptomsType {
  data: Array<string>
}

export interface PickType {
  value: string
}

export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
}

export interface HealthIdType {
  errors:[{ error: string }]
}

export interface HealthResponse {
	data: [
	  {
    email_id: string;
		full_phone_number: string;
		health_id: string;
		label: string;
		value: string;
    number: string;
    full_name: string;
	  }
  ]
}

export interface HealthResponseType {
	data: 
	  {		
		label: string;
		value: string;
    number: string
	  }
}
// Customizable Area End 

interface S {
  // Customizable Area Start
  showPopup: boolean;
  selectedDate: string;
  symptomsData: Array<SymptomsType>;
  showAddNewScheduleModal: boolean;
  modeSelect: string;
  patientHealthId: string | null;
  healthIdData: HealthResponse;
  selectAppointmentDateData: Array<AppointmentType>;
  getTimeSlotsData: Array<TimeSlots>;
  healthId: string;
  categoryType: string;
  symptomsErr: string;
  is_Loader: boolean;
  height: number;
  patientNumber: string,
  healthIdErr: string;
  selectedHealthId: string;
  pickTime: string,
  selectPatientNumber: string;
  patientSymtoms: string,
  doctorFullName: string | null,
  noAppointmentsData: boolean,
  doctorNumber: string | any,
  modeType: any,
  appointmentDate: string,
  timeSlot: string,
  getSymptoms: Array<SelectSymptomsType>,
  doctorId: string | null,
  pickValue: Array<PickType>,
  symptomsShow: boolean,
  doctorProfileDetailsData: object,
  activeTimeZone: string,
  activeTimeSlot: any
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class DoctorScheduleController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getSymptomsApiCallId: string | null = null;
  getSelectDateAppointmentApiCallId: string | null = null;
  getHealthIdApiCallId: string = "";
  getTimeSlotApiCallId: string | null = null;
  postDoctorAddNewScheduleApiCallId: string = "";
  getDoctorProfileApiCallId: string = "";
  formRef: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      pickTime: "",
      showPopup: false,
      symptomsData: [],
      showAddNewScheduleModal: false,
      modeSelect: "",
      selectedDate: "",
      selectAppointmentDateData: [],
      getTimeSlotsData: [],
      healthId: "",
      patientNumber: "",
      patientSymtoms: "",
      doctorFullName: "",
      doctorNumber: "",
      modeType: "",
      noAppointmentsData: false,
      getSymptoms: [],
      appointmentDate: "",
      timeSlot: "",
      doctorId: "",
      pickValue: [
        { value: "Morning" },
        { value: "Afternoon" },
        { value: "Evening" }
      ],
      symptomsShow: false,
      doctorProfileDetailsData: {},
      activeTimeZone: "",
      activeTimeSlot: "",
      is_Loader: false,
      height: 700,
      patientHealthId: "",
      healthIdData: {
        data: [
          {
            email_id: "",
            full_phone_number: "",
            health_id: "",
            full_name: "",
            label: "",
            value: "",
            number: ""
          }
        ]
      },
      selectedHealthId: "",
      categoryType: "",
      selectPatientNumber: "",
      healthIdErr: "",
      symptomsErr: ""
      // Customizable Area End
    };
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    this.formRef = React.createRef<FormInstance>();
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    let currentDate = moment().format("YYYY-MM-DD")
    this.setState({ selectedDate: currentDate }, () => { this.getSelectDateAppointmentDataShow() })
    this.getsymptomsDataShow();
    this.setState({ doctorId: localStorage.getItem("doctorId") })
    this.getDoctorProfileDetails();
    this.setState({ doctorFullName: localStorage.getItem("doctorName") });
    this.setState({ doctorNumber: localStorage.getItem("doctorNum") }, () => {
      if (this.state.doctorNumber?.length > 10) {
        return this.setState({ doctorNumber: this.state.doctorNumber.replace(/^91/, '') });
      } else {
        return this.setState({ doctorNumber: this.state.doctorNumber });
      }
    })
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson.status === 500) {
        MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
        return;
      }
      if (this.isValidResponse(responseJson)) {
        this.apiSuccessCallBacks(apiRequestCallId, responseJson);
      } else if (this.isValidResponseForAppointmentKey(responseJson)) {
        this.apiSuccessCallBacksForAppointmentKey(apiRequestCallId, responseJson);
      }
      else if (this.isInValidResponse(responseJson)) {
        this.apiFailureCallBacks(apiRequestCallId, responseJson);
      } else if (errorReponse) {
        notification["error"]({
          message: "Internal Server Error."
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  isValidResponse = (responseJson: any) => {
    return responseJson && responseJson.data;
  };

  isValidResponseForAppointmentKey = (responseJson: any) => {
    return responseJson && responseJson.appointment;
  };

  isInValidResponse = (responseJson: any) => {
    return (responseJson && responseJson.errors) || responseJson;
  };
  // Customizable Area End

  // Customizable Area Start
  apiSuccessCallBacksForAppointmentKey = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getSelectDateAppointmentApiCallId) {
      this.getSelectDateAppointmentSuccessCallBack(responseJson);
    }
  };

  apiSuccessCallBacks = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getSymptomsApiCallId) {
      this.getSymptomsSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.getTimeSlotApiCallId) {
      this.getTimeSlotSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.postDoctorAddNewScheduleApiCallId) {
      this.postDoctorAddNewScheduleSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.getDoctorProfileApiCallId) {
      this.getDoctorProfileDetailSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.getHealthIdApiCallId) {
      this.getHealthIdSuccessCallBack(responseJson);
    }
  };

  apiFailureCallBacks = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getSymptomsApiCallId) {
      this.getSymptomsFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.getSelectDateAppointmentApiCallId) {
      this.getSelectDateAppointmentFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.getTimeSlotApiCallId) {
      this.getTimeSlotFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.postDoctorAddNewScheduleApiCallId) {
      this.postDoctorAddNewScheduleFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.getHealthIdApiCallId) {
      this.getHealthIdFailureCallBack(responseJson);
    }
  };
  // Customizable Area End

  // Customizable Area Start
  getsymptomsDataShow = async () => {
    this.getSymptomsApiCallId = ApiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.getSymptomsApiMethodType,
      endPoint: `${configJSON.getSymptomsEndpoint}?is_request=web`,
    });
  };

  getSymptomsSuccessCallBack = (res: any) => {
    this.setState({
      symptomsData: res.data
    })
  }

  getSymptomsFailureCallBack = (err: any) => {
    notification["error"]({
      message: err
    });
  }

  changeSelectIcon = (value: any) => {
    this.setState({
      modeType: value
    })
  };

  getSelectDateAppointmentDataShow = async () => {
    this.getSelectDateAppointmentApiCallId = ApiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.getSymptomsApiMethodType,
      endPoint: `${configJSON.getAppointmentsByDateEndpoint}?appointment_date=${this.state.selectedDate}`,
    });
  };

  getSelectDateAppointmentSuccessCallBack = (res: any) => {
    this.setState({
      selectAppointmentDateData: res.appointment?.data
    })
  }

  getSelectDateAppointmentFailureCallBack = (err: any) => {
    if (err.errors) {
      this.setState({
        selectAppointmentDateData: [],
      })
      notification["error"]({
        message: "No appointments found"
      });
    }
  }

  showProfileDetailsHandler = () => {
    this.setState({ showPopup: !this.state.showPopup });
  }

  handleDocLogout = () => {
    localStorage.clear();
    notification["success"]({
      message: "Logout succesfully",
    });
  }

  handleAppointmentByDate = (date: any) => {
    let currentDate = moment(date).format("YYYY-MM-DD")
    this.setState({
      selectedDate: currentDate
    }, () => {
      this.getSelectDateAppointmentDataShow();
    })
  }

  handleScheduleTime = (value: any) => {
    this.setState({
      timeSlot: value,
      activeTimeSlot: value
    })
  }

  getTimeSlotShow = async (item: string) => {
    let currentDate = moment(this.state.appointmentDate).format("DD-MM-YYYY")
    this.setState({
      pickTime: item,
      activeTimeZone: item
    }, () => {
      this.getTimeSlotApiCallId = ApiCall({
        contentType: configJSON.validationApiContentType,
        method: configJSON.getSymptomsApiMethodType,
        endPoint: `${configJSON.getTimeSlotEndpoint}?[data][attributes][appointment_date]=${currentDate}&[data][attributes][mode_type]=${this.state.modeType}&[data][attributes][time_slot]=${this.state.pickTime}&[data][attributes][doctor_id]=${this.state.doctorId}&[data][attributes][user_type]=doctor`
      });
    })
  };

  getTimeSlotSuccessCallBack = (res: TimeslotsType) => {
    this.setState({
      getTimeSlotsData: res.data,
      pickTime: "",
    })
  }

  getTimeSlotFailureCallBack = (err: any) => {
    if (err.errors[0].message) {
      this.setState({
        getTimeSlotsData: [],
      })
      notification["error"]({
        message: err.errors[0].message
      });
    }
  }

  postDoctorAddNewSchedule = async () => {
    const findEndTime= this.state.getTimeSlotsData.filter((data) => {
      return data.start_time === this.state.timeSlot
    })
    let apiBody = {
      data: {
        attributes: {
          health_id: this.state.healthId,
          patient_phone_number: this.state.patientNumber,
          symtoms: this.state.patientSymtoms,
          doctor_full_name: this.state.doctorFullName,
          doctor_phone_number: this.state.doctorNumber,
          mode_type: this.state.modeType,
          appointment_date: this.state.appointmentDate,
          time_slot: this.state.timeSlot,
          doctor_id: this.state.doctorId,
          consultation_category: this.state.modeType,
          consultation_type: this.state.categoryType,
          fees: 3000,
          end_time: findEndTime[0].end_time
        }
      }
    }
    this.postDoctorAddNewScheduleApiCallId = ApiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.postAddNewScheduleApiMethodType,
      endPoint: `${configJSON.postAddNewScheduleEndpoint}?is_request=web`,
      body: apiBody,
    });
    this.setState({is_Loader: true});
  };

  postDoctorAddNewScheduleSuccessCallBack = (res: any) => {
    this.setState({
      showAddNewScheduleModal: false,
      getTimeSlotsData: [],
      is_Loader: false,
      pickTime: "",
      activeTimeZone: "",
      modeType: "",
      patientNumber: "",
      selectPatientNumber: "",
      symptomsErr: ""
    })
    notification["success"]({
      message: "Appointment created succesfully"
    });
    this.onAddNewScheduleFormReset();
    this.getSelectDateAppointmentDataShow();
  };

  postDoctorAddNewScheduleFailureCallBack = async (err: any) => {
    this.setState({is_Loader: false});
    if (err.appointment_time) {
      notification["error"]({
        message: "This patient appointment already booked other doctor"
      });
    }
  }

  onAddNewSchedule = async (values: {
    healthid: string, patientnumber: string, doctorname: string, doctornumber: string, modeSelect: string
  }) => {
    this.setState(
      {
        healthId: this.state.selectedHealthId,
        patientNumber: this.state.selectPatientNumber,
        patientSymtoms: this.state.patientSymtoms,
        doctorFullName: this.state.doctorFullName,
        doctorNumber: this.state.doctorNumber,
        modeType: values.modeSelect,
        appointmentDate: this.state.appointmentDate,
        timeSlot: this.state.timeSlot,
        doctorId: this.state.doctorId,
      }, () => {
        this.postDoctorAddNewSchedule()
      }
    );
  };

  handleScheduleDate: DatePickerProps['onChange'] = (date, dateString) => {
    this.setState({
      appointmentDate: dateString
    })
  };

  handleScheduleModal = () => {
    this.setState({
      showAddNewScheduleModal: !this.state.showAddNewScheduleModal,
      selectedHealthId: "",
      selectPatientNumber: "",
      patientHealthId: "",
      modeType: "",
      pickTime: "",
      getTimeSlotsData: [],
      activeTimeZone: "",
      patientNumber: "",
      symptomsErr: ""
    })
    this.onAddNewScheduleFormReset();
  }

  onAddNewScheduleFormReset = () => {
    this.formRef.current?.resetFields();
  };

  handleSymptomsChange = (value: string) => {
    this.setState({
      patientSymtoms: value
    });
  };

  getDoctorProfileDetails = async () => {
    this.getDoctorProfileApiCallId = ApiCall({
      contentType: configJSON.getSymptomsApiContentType,
      method: configJSON.getSymptomsApiMethodType,
      endPoint: `${configJSON.getDoctorDetailsApiEndPoint}?is_request=web`,
    });
  };

  getDoctorProfileDetailSuccessCallBack = (res: any) => {
    localStorage.setItem("doctorName", res.data.attributes.full_name)
    localStorage.setItem("doctorNum", res.data.attributes.full_phone_number)
    this.setState({
      doctorProfileDetailsData: res
    });
  };

  handleDoctorDetailsPage = () => {
    this.props.history.push("/doctorprofiledetails");
  };

  handleEditDoctorProfile = () => {
    this.props.history.push("/editdoctordetails")
  };

  handleAppointmentsDetails = (id: number) => {
    this.props.history.push({
      pathname: "/appointmentsdetails",
      state: {
        appointmentId: id
      }
    })
  };

  handlePopupClose = () => {
    this.setState({
      showPopup: false
    });
  };

  disabledPastDate = (current: moment.Moment): boolean => {
    return current && current < moment().startOf("day");
  };

  subCategoryApiCall = (data: APIPayloadType) => {
    const token = localStorage.getItem("token");
    let { contentType, method, endPoint, body } = data;
    const header = {
        "Content-Type": contentType,
        token
    };
    let requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
    );
    body &&
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
    }

  getHealthIdFunction = async () => {
    this.getHealthIdApiCallId = this.subCategoryApiCall({
      contentType: configJSON.getSymptomsApiContentType,
      method: configJSON.getSymptomsApiMethodType,
      endPoint: `${configJSON.getHealthIdEndpoint}?search=${this.state.patientHealthId}`
    });
    this.setState({is_Loader: true});
  };

  getHealthIdSuccessCallBack = (response: HealthResponse) => {
    this.setState({is_Loader: false});
     response.data.forEach((obj: { email_id: string; full_phone_number: string; health_id: string; label: string; value: string; number: string;}) => {
      obj.label = obj.health_id;
      obj.value = obj.health_id;
      obj.number = obj.full_phone_number;
  });
    this.setState({healthIdData: response});
  };

  getHealthIdFailureCallBack = (responseJson: HealthIdType) => {
    this.setState({is_Loader: false});
    if(responseJson){
      notification["error"]({
        message: "No data found"
      });
    }
  };

  handleHealthId = (value: string) => {
    this.setState({
      patientHealthId: value
    },() => {
        this.getHealthIdFunction();
    });
  };
 
  handleSelectId = (value: string , number: string) => {
    this.setState({
      selectedHealthId: value,
      selectPatientNumber: number
    });
  };

  handleCategoryType = (value: string) => {
  this.setState({categoryType : value});
  };

  handleChatNavigate = () => {
    this.props.history.push("/chat")
  };
  // Customizable Area End
}