import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
// Customizable Area Start
import { message as MESSAGE, notification } from "antd";
import ApiCall from "../../../components/src/ApiCall.web";
import * as H from "history";
const navigation = require("react-navigation");
const location = require("react-navigation");
// Customizable Area End
export const configJSON = require("./config.js");
// Customizable Area Start
export interface AppointmentsImagesType {
  id: number;
  url: string;
}
// Customizable Area End
// Customizable Area Start
export interface AppointmentNameType {
  id: number;
  name: string;
}

export interface DownloadType {
    link : string
}
// Customizable Area End

// Customizable Area Start
export interface AppointmentlsType {
  id: string;
  type: string;
  attributes: {
    id: number;
    doctor_full_name: string;
    patient_year: number;
    mode_type: string;
    doctor_gender: string;
    doctor_age: number;
    appointment_date: number;
    time_slot: number | string;
    appointment_type: string;
    doctor_profile_image: Array<AppointmentsImagesType>;
    status:number,
    is_prescription_present:boolean,
  };
}

export interface PrescriptionType {
  data: {
    id: string;
    type: string;
    attributes: {
      medicine_name: string;
      duration: string;
      time: string;
      comments: string;
      dose_time: string;
      patient_id: string;
      doctor_id: string;
      appointment_id: string;
      add_extra_information: {
        diet_to_follow: string;
        patient_history: string;
        follow_up_physician: string;
        follow_up: string;
      };
      image: string;
      doctor_full_name: string;
      prescription_medicines: {
        data: Array<PrescriptionMecine>
      }
    }
  }
}
export interface PrescriptionMecine {
    "id": string,
    "type": string,
    "attributes": {
        "id": number,
        "medicine_name": string,
        "duration": string,
        "time": string
        "comments": string
        "dose_time": string
    }
}

export interface AppoinmentsType {
  data : Array<AppointmentlsType>
}

export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
}

interface AppointmentDetail {
  id: string;
  type: string;
  attributes: {
    doctor_id: number;
    patient_id: number;
    patient_phone_number: string;
    time_slot: string;
    end_time: string | null;
    consultation_category: string;
    pick_time: string | null;
    consultation_type: string;
    fees: number;
    mode_type: string;
    doctor_full_name: string;
    status: number;
    appointment_date: string;
    appointment_created_time: string;
    appointment_type: string;
    is_prescription_present: boolean;
  };
}

interface CancelAppointments {
  cancel_appointments: {
    data: AppointmentDetail[]
  }
}
// Customizable Area End
export interface Props {
  // Customizable Area Start
  navigation: typeof navigation;
  id: string;
  history: H.History;
  location: typeof location;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  AppointmentlsDataList: AppoinmentsType;
  previousAppointmetDataList:AppoinmentsType;
  isViewPrescriptionMOdalOpen:boolean,
  appointmentID:string,
  prescriptionDetails: PrescriptionType;
  cancelAppointmentData: CancelAppointments;
  appoitmentType: string;
  newAppointmentsData: Array<AppointmentDetail>;
  pastAppointmentsData: Array<AppointmentDetail>;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}
export default class PatientAppointmentsPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAppointmentlsApiCallId: string | null = null;
  previousAppointmentApiCalledId:string | null = null;
  getprescriptionAppointmentApiCalledId:string | null = null;
  downloadPrescriptionApiCalledId:string | null = null;
  getCancelledAppointmentsApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      // Customizable Area Start
      AppointmentlsDataList: {
        data : [
          {
            id: "",
            type: "",
            attributes: {
              id: 0,
              doctor_full_name: "",
              patient_year: 0,
              mode_type: "",
              doctor_gender: "",
              doctor_age: 0,
              appointment_date: 0,
              time_slot: "",
              appointment_type: "",
              doctor_profile_image: [
                {
                  id: 0,
                  url: ""
                }
              ],
              status: 0,
              is_prescription_present: false,
            }
          }
        ]
      },
      previousAppointmetDataList:{
        data : [
          {
            id: "",
            type: "",
            attributes: {
              id: 0,
              doctor_full_name: "",
              patient_year: 0,
              mode_type: "",
              doctor_gender: "",
              doctor_age: 0,
              appointment_date: 0,
              time_slot: "",
              appointment_type: "",
              doctor_profile_image: [
                {
                  id: 0,
                  url: ""
                }
              ],
              status: 0,
              is_prescription_present: false,
            }
          }
        ]
      },
      isViewPrescriptionMOdalOpen:false,
      appointmentID:"",
      prescriptionDetails:{
        data: {
          id: "",
          type: "",
          attributes: {
            medicine_name: "",
            duration: "",
            time: "",
            comments: "",
            dose_time: "",
            patient_id: "",
            doctor_id: "",
            appointment_id: "",
            add_extra_information: {
              diet_to_follow: "",
              patient_history: "",
              follow_up_physician: "",
              follow_up: ""
            },
            image: "",
            doctor_full_name: "",
            prescription_medicines: {
              data: [
                {
                 id: "",
                 type: "",
                 attributes: {
                     id: 0,
                     medicine_name: "",
                     duration: "",
                     time: "",
                     comments: "",
                     dose_time: ""
                  }
              }
              ]
            }
          }
        }
      },
      cancelAppointmentData: {
        cancel_appointments : {
          data: []
        }
      },
      appoitmentType: "",
      newAppointmentsData: [],
      pastAppointmentsData: []
      // Customizable Area End
    };
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    this.getAppointmentlsShow();
    this.getPreviousAppointmentData();
    this.getCancelledAppointmentsFunction();
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.status === 500) {
        MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
        return;
      }

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (responseJson) {
        this.apiSuccessCallBacks(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  apiSuccessCallBacks = (apiRequestCallId: string, responseJson: AppoinmentsType & PrescriptionType & DownloadType & CancelAppointments) => {
    if (apiRequestCallId === this.getAppointmentlsApiCallId) {
      this.getAppointmentlsSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.previousAppointmentApiCalledId) {
      this.getpreviousAppointmentlsSuccessCallBack(responseJson);
    }
    if(apiRequestCallId === this.getprescriptionAppointmentApiCalledId){
      this.getPrecriptionAppointmentSuccessCallBack(responseJson)
    }
    if(apiRequestCallId === this.downloadPrescriptionApiCalledId){
      if(responseJson.link){
        this.handleDownloadPrescription(responseJson.link)
      }
      this.setState({isViewPrescriptionMOdalOpen:false});
    }
    if(apiRequestCallId === this.getCancelledAppointmentsApiCallId){
      this.getCancelledAppointmentsSuccessCallBack(responseJson)
    }
  };
  // Customizable Area End

  // Customizable Area Start
  apiFailureCallBacks = (apiRequestCallId: string, responseJson: object) => {};
  // Customizable Area End

  getAppointmentlsShow = async () => {
    this.getAppointmentlsApiCallId = ApiCall({
      contentType: configJSON.getAppointmentsApiContentType,
      method: configJSON.getAppointmentsAPiMethod,
      endPoint: `${configJSON.getUpcomingAppointmentEndPoint}`,
    });
  };

  getPreviousAppointmentData=()=>{
    this.previousAppointmentApiCalledId = ApiCall({
      contentType: configJSON.getAppointmentsApiContentType,
      method: configJSON.getAppointmentsAPiMethod,
      endPoint: `${configJSON.previousAppointmentEndPoint}`,
    });
  }

  getPrescriptionData=(appointId:string)=>{
    this.getprescriptionAppointmentApiCalledId = ApiCall({
      contentType: configJSON.getAppointmentsApiContentType,
      method: configJSON.getAppointmentsAPiMethod,
      endPoint: `${configJSON.getPrescriptionDataEndPoint}?appointment_id=${appointId}`,
    });
  }


  downloadViewPrescription=(appointmentId:string)=>{
    this.downloadPrescriptionApiCalledId = ApiCall({
      contentType: configJSON.getAppointmentsApiContentType,
      method: configJSON.getAppointmentsAPiMethod,
      endPoint: `${configJSON.downloadPrescriptionEndPoint}?appointment_id=${appointmentId}`,
    });
  }

  handleDownloadPrescription=(pdfUrl:string)=>{
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.setAttribute('download', 'document.pdf');

      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link)
  }

  getAppointmentlsSuccessCallBack = (response: AppoinmentsType) => {
    this.setState({
      AppointmentlsDataList: response,
    });
  };
 
  getpreviousAppointmentlsSuccessCallBack = (response: AppoinmentsType) => {
    this.setState({
      previousAppointmetDataList: response,
    });
  };

  getPrecriptionAppointmentSuccessCallBack =(response:PrescriptionType)=>{
    this.setState({
      prescriptionDetails:response
    },()=>{
      this.setState({isViewPrescriptionMOdalOpen:true})
    })
  }

  handleBookedAppointment=(item:AppointmentlsType)=>{
    if(item?.attributes?.status ===2){
      return 
    }
    this.props.history.push({pathname: "/patientAppointmentjoiningPage",state:{item:item}});
  }

  navigateToShowUpcomingAppointment=()=>{
    this.props.history.push({pathname:"/upcoming-appointment"});
  }
  navigateToShowPreviousAppointment=()=>{
    this.props.history.push({pathname:"/previous-appointment"});
  }

  navigateToShowCancelledAppointment=()=>{
    this.props.history.push({pathname:"/cancelled-appointment"});
  }

  handlePrescription=(item:AppointmentlsType)=>{
    this.getPrescriptionData(item?.id)
    this.setState({isViewPrescriptionMOdalOpen:true,appointmentID:item?.id});
  }

  handleViewPrescription=()=>{
    this.downloadViewPrescription(this.state.appointmentID);
  }

  handleClosPrescriptionModal = () => {
  this.setState({
    isViewPrescriptionMOdalOpen: false
  });
  };

  apiCall = (apiData: APIPayloadType) => {
    const { contentType, method, endPoint, body } = apiData;
    let token = localStorage.getItem("token");
    let header = {
      token,
      "Content-Type": contentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
       JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getCancelledAppointmentsFunction = async () => {
    this.getCancelledAppointmentsApiCallId = this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getCanceledAppointmentEndPoint
    });
  };

  getCancelledAppointmentsSuccessCallBack = (response: CancelAppointments) => {
    this.setState({ cancelAppointmentData: response });
    const newAppointments = response.cancel_appointments.data.filter(appointment => appointment.attributes.appointment_type === 'New');
    const pastAppointments = response.cancel_appointments.data.filter(appointment => appointment.attributes.appointment_type === 'Past');
    this.setState({
      newAppointmentsData: newAppointments,
      pastAppointmentsData: pastAppointments
    });
  };

}
// Customizable Area End
