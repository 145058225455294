import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
// Customizable Area Start
import ApiCall from "../../../components/src/ApiCall.web";
import React from "react"
import moment from "moment";
import { notification, DatePickerProps } from "antd";
// Customizable Area End
export const configJSON = require("./config.js");
// Customizable Area Start
export interface AppointmentImagesType {
  id: number;
  url: string;
}
// Customizable Area End
// Customizable Area Start

// Customizable Area End

// Customizable Area Start
export interface AppointmentListType {
  id: string;
  type: string;
  attributes: {
    id: number;
    doctor_full_name: string;
    patient_year: number;
    mode_type: string;
    doctor_gender: string;
    doctor_age: number;
    appointment_date: number;
    time_slot: number | string;
    appointment_type: string;
    doctor_profile_image: Array<AppointmentImagesType>;
  };
}
// Customizable Area End
export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  history: any;
  location: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  AppointmentlsDataList: Array<AppointmentListType>;
  bookedAppointmentDetail:any,
  isModalOpen:boolean,
  isrescheduleModalOpen:boolean,
  isCancellModalOpen:boolean,
  consultation_type:string,
  fees: number,
  mode_type: string,
  doctor_full_name:string,
  doctor_phone_number: string,
  appointment_date:string,
  time_slot:string,
  doctor_experience:string,
  doctor_category:string,
  doctor_profile_image:string,
  MultilineText:any,
  appointmentId:string,
  showPopup:boolean,
  consultation_category:string,
  currentDateTime:string,
  isConsultationStart:boolean,
  minutesBefore:string,
  isBeginConsultation:boolean,
  isJoinConsultation:boolean,
  minutes:number,
  seconds:number,
  activeTab:string,
  selected_date:string,
  active_time_slot_section:string,
  selectedMode:string,
  onlineTimeSlot:any,
  isTimeSlotSelected:boolean,
  selectedTimeSlotMorning:string,
  end_time:string
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}
export default class PatientAppointmentJoiningPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAppointmentlsApiCallId: string | null = null;
  cancelAppointmentApiCallId: string | null = null;
  getVirtualTimeSlotApiCallId:string | null = null;
  getInClinicTimeSlotApiCalledId:string | null = null;
  updateappointmentCallId : string | null = null
  // Customizable Area End
  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      // Customizable Area Start
      AppointmentlsDataList: [],
      bookedAppointmentDetail:"",
      isModalOpen:false,
      isrescheduleModalOpen:false,
      isCancellModalOpen:false,
      consultation_type:"",
      mode_type:"",
      doctor_full_name:"",
      doctor_phone_number:"",
      fees:0,
      appointment_date:"",
      time_slot:"",
      doctor_experience:"",
      doctor_category:"",
      doctor_profile_image:"",
      MultilineText:[],
      appointmentId:"",
      showPopup:false,
      consultation_category:"",
      currentDateTime:"",
      isConsultationStart:false,
      minutesBefore:'',
      isBeginConsultation:false,
      isJoinConsultation:false,
      minutes:0,
      seconds:0,
      activeTab:"Virtual",
      selected_date:'',
      active_time_slot_section:"Morning",
      selectedMode:"",
      onlineTimeSlot:[],
      isTimeSlotSelected:false,
      selectedTimeSlotMorning:"",
      end_time:""
      // Customizable Area End
    };
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    this.setState({
      doctor_full_name: this.props.location.state.item.attributes.doctor_full_name,
      appointment_date: this.props.location.state.item.attributes.appointment_date,
      consultation_type:this.props.location.state.item.attributes.consultation_type,
      time_slot:this.props.location.state.item.attributes.time_slot,
      doctor_experience:this.props.location.state.item.attributes.doctor_experience,
      fees:this.props.location.state.item.attributes.original_price, 
      doctor_category:this.props.location.state.item.attributes.doctor_category, 
      doctor_profile_image:this.props.location.state.item.attributes.doctor_profile_image,
      appointmentId:this.props.location.state.item.id,
      consultation_category:this.props.location.state.item.attributes.mode_type,
      end_time: this.props.location.state.item.attributes.end_time,
      selected_date:moment().format("DD-MM-YYYY")
      }, () => {
        this.subtractTenMinutes();
       })
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if(apiRequestCallId === this.cancelAppointmentApiCallId){
        if(responseJson.message){
          notification["success"]({
            message:`Your Appointment has been cancelled successfully`,
          });
          this.setState({isModalOpen:false,isrescheduleModalOpen:false,isCancellModalOpen:false});
          this.props.history.push("/PatientAppointmentsPage")
        }else{
          notification["error"]({
            message:`${responseJson.errors[0]}`,
          });
          this.setState({isModalOpen:false,isrescheduleModalOpen:false,isCancellModalOpen:false});
        }
      }
      if(apiRequestCallId === this.getVirtualTimeSlotApiCallId){
        this.setState({onlineTimeSlot:responseJson?.data});
              this.showToast(responseJson)
      }
      if(apiRequestCallId === this.getInClinicTimeSlotApiCalledId){
        this.setState({onlineTimeSlot:responseJson?.data});
              this.showToast(responseJson)
      }
      if(apiRequestCallId === this.updateappointmentCallId){
        this.setState({isrescheduleModalOpen : false}, () => this.props.history.push("/PatientAppointmentsPage"))
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  // Customizable Area Start
 
  // Customizable Area End

  cancelAppointment = async () => {
    this.cancelAppointmentApiCallId = ApiCall({
      contentType: configJSON.getAppointmentsApiContentType,
      method: configJSON.updateManageAddressAPiMethod,
      endPoint: `${configJSON.cancelAppointmentEndPoint}?id=${this.state.appointmentId}`,
    });
  };
  showToast = (doctorResponseJson:any) => {
    if(doctorResponseJson?.errors[0].message){
      notification["error"]({
        message: doctorResponseJson.errors[0].message})

    }
  }
  handleStartAppointments = () => {
    switch(this.state.consultation_type){
      case "Text Consultation":
        this.setState({isModalOpen:true});
        break;
      case "Phone Consultation":
        this.setState({isModalOpen:true, isBeginConsultation:false});
        break;
      default:
        this.props.history.push({ pathname: "videoscreen", state: { item: this.props.location.state.item } }) 
    }   
  }
  handleTimeSlotSection=(item:any)=>{
    this.setState({selectedTimeSlotMorning:""});
    const { consultation_category} = this.state
    this.setState({active_time_slot_section:item},()=>{
     consultation_category  === "Virtual" ? this.getVirtualTimeSlot('Virtual') :this.getInClinicTimeSlot("In - Clinic")
    })  
  }
  closeModal = () => {
    this.setState({isrescheduleModalOpen:false})
  }
  handleSetTimeSlot=(item:any,index:any)=>{
    this.setState({isTimeSlotSelected:true});
    this.setState({selectedTimeSlotMorning:index});
    this.setState({time_slot:item});
  }
  handleModalClose=(event:any,reason:any)=>{
    if(reason !== 'backdropClick'){
      this.setState({isModalOpen:false,isrescheduleModalOpen:false,isCancellModalOpen:false});
    } 
  }

  handleCancelAppointment=(event:any,reason:any)=>{
    if(reason !== 'backdropClick'){
      this.cancelAppointment();
      this.setState({isCancellModalOpen:false})
    } 
  }
  handleRescheduleAppointments=()=>{
    this.setState({isrescheduleModalOpen:true})
  }

  handleOpenCancelAppointmentModal=()=>{
    this.setState({isCancellModalOpen:true})
  }

  showDetailsHandler = () => {
    this.setState({ showPopup: !this.state.showPopup });
  };

  handleLogout=()=>{
    localStorage.clear();
    notification["success"]({
        message: "Logout succesfully",
      });
  };

  navigateToRescheduleAppointment=()=>{
    this.props.history.push({pathname:"/reschedule"});
    this.setState({isModalOpen:false});
    this.setState({isrescheduleModalOpen:false});
  }

  navigateToStartAppointment=()=>{
    this.props.history.push({pathname:"/startappointment"});
    this.setState({isrescheduleModalOpen:false});
    this.setState({isModalOpen:false});
  }
  navigateToCancellAppointment=()=>{
    this.props.history.push({pathname:"/cancelappointment"});
    this.setState({isCancellModalOpen:false});
    this.setState({isrescheduleModalOpen:false});
  }

  handleStartAppointment=()=>{
    localStorage.clear();
    notification["success"]({
        message: "Start appointment succesfully",
      });
  }
  disabledPastDate = (current: any) => {
    return current && current <= moment().startOf("day");
  }
  onDateChange: DatePickerProps['onChange'] = (date, dateString) => {
    const {consultation_category} = this.state;
    let currentDate = moment(dateString).format("DD-MM-YYYY")
    this.setState({selected_date: currentDate},() => {
      consultation_category  === "Virtual" ? this.getVirtualTimeSlot('Virtual') :this.getInClinicTimeSlot("In - Clinic")
    })
  };
  getVirtualTimeSlot=(activeTab:string)=>{
  const doctorId = this.props.location.state.item.attributes.doctor_id

    const header = {

      "Content-Type": configJSON.getProfileDetailsContentType,
      "token":localStorage.getItem("token")
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getVirtualTimeSlotApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_appointment_management/time_slot?[data][attributes][appointment_date]=${this.state.selected_date}&[data][attributes][mode_type]=${activeTab}&[data][attributes][doctor_id]=${doctorId}&[data][attributes][time_slot]=${this.state.active_time_slot_section}&[data][attributes][user_type]=patient`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
}
getInClinicTimeSlot=(activeTimeSlot:string)=>{
  const doctorId = this.props.location.state.item.attributes.doctor_id
  const header = {

    "Content-Type": configJSON.getProfileDetailsContentType,
    "token":localStorage.getItem("token")
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getInClinicTimeSlotApiCalledId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `/bx_block_appointment_management/time_slot?[data][attributes][appointment_date]=${this.state.selected_date}&[data][attributes][mode_type]=${activeTimeSlot}&[data][attributes][doctor_id]=${doctorId}&[data][attributes][time_slot]=${this.state.active_time_slot_section}&[data][attributes][user_type]=patient`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);

  return true;


}
handleRescheduleAppointmentApi=()=>{
  const {time_slot, end_time,selected_date,consultation_category, active_time_slot_section}= this.state
    const header = {
      "token":localStorage.getItem("token")
    };
    const formdata = new FormData()
    formdata.append("appointment_date", selected_date);
    formdata.append("pick_time", active_time_slot_section);
    formdata.append("start_time", time_slot);
    formdata.append("end_time", end_time);
    formdata.append("mode_type",consultation_category);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateappointmentCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_appointment_management/reschedule_appointment?id=${this.props.location.state.item.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateManageAddressAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
}
  handleRescheduleAppointment=()=>{
    localStorage.clear();
    notification["success"]({
        message: "Reschedule appointment succesfully",
      });
  }
  handleConsultationBeginModal = () => {
    this.setState({ isBeginConsultation: false });
};
subtractTenMinutes = () => {
  const { appointment_date, time_slot } = this.props.location.state.item.attributes;
  const appointmentDateTimeString = `${appointment_date} ${time_slot}`;
  const appointmentDateTime = new Date(appointmentDateTimeString);
  const consultationStartTime = new Date(appointmentDateTime.getTime() - 10 * 60000);
  const currentDateTime = new Date();
  if (currentDateTime >= consultationStartTime) {
      this.setState({
          isConsultationStart: true,
          minutesBefore: consultationStartTime.toLocaleString()
      });
  } else {
      notification["error"]({
          message: "Please wait for the consultation to start",
      });
  }
};

handleJoinConsultationModal = () => {
  this.setState({ isJoinConsultation: false });
};

handleJoinModal = () => {
  this.setState({ isJoinConsultation: true } );
};
handleBeginConsultation = () => {
  this.setState({
      isBeginConsultation: true,
      isJoinConsultation: false
  });
  const timerInterval = setInterval(() => {
      let current = (new Date()).getTime()
      let appointment = ((new Date(this.state.minutesBefore)).getTime()) + (10*60*1000)
      let remainingMinute = appointment-current;
      const currentSec = (new Date()).getSeconds();
      if (remainingMinute < 0 ) {
          clearInterval(timerInterval);
          {this.handleStartAppointments()}
      } else {
          this.setState({
              minutes: Math.floor((Math.floor(remainingMinute/1000)%3600)/60),
              seconds: 60 - currentSec
          });
      }
  }, 1000);
};
}

// Customizable Area End