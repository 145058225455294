import React from "react";
// Customizable Area Start
import { Avatar, Row, Layout, Typography, Menu, Space } from "antd";
import NavbarCommonComponent from "../../../components/src/NavbarCommonComponent.web";
import {
  Myorder,
  healthrecords,
  Linkinsurance,
  homaddress,
  Paymentmethod,
  settings,
  contactus,
  logout,
  switchaccount,
  edit,
  qrcode,
  RightOutlined,
  calendarimg,
} from "./assets";
import { Link } from "react-router-dom";
import ProfilePageOutLet from "./ProfileOutletController.web";
import PatientProfilePopup from "../../../components/src/PatientProfilePopup.web";
import { Default } from "../../splashscreen/src/assets";
let config = require("../../../framework/src/config.js").baseURL;

// Customizable Area End

class PatientOutLet extends ProfilePageOutLet {
  // Customizable Area Start
  renderNavigationTitle = () => {
    return (
      <>
        <div
          className="navigationContainer"
          style={{ boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.03)" }}
        >
          <Link to="/patienthome">Home</Link>
          <Link to="/patient-appointment-landingpage">Appointments</Link>
          <Link to="/services">Services</Link>
          <Link to="/patientmessage">Messages</Link>
        </div>
      </>
    );
  };
  // Customizable Area End
  // Customizable Area Start
  render() {
    return (
      <div>
        <NavbarCommonComponent
          handleProfile={this.showDetailsHandler}
          data-test-id="handleshowId"
        />
        <PatientProfilePopup
          data-test-id="handleLogoutId"
          profileData={this.state.showPopup}
          logoutFunctiuon={this.handleLogout}
        />
        {this.renderNavigationTitle()}

        <Layout className="profile-page-wrapper">
          <Layout.Sider
            style={{ background: "#FFF" }}
            className="lay-out-sider-css"
            breakpoint="lg"
            collapsedWidth="0"
            width={350}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              <Row align={"middle"} className="profileCard">
                <>
                  {this.state?.patientDetails?.profile_photo ? (
                    <Avatar
                      size="large"
                      src={config + this.state.patientDetails.profile_photo}
                    />
                  ) : (
                    <Avatar size="large" src={Default} />
                  )}

                  <Typography.Title level={4} style={{ margin: "0" }}>
                    {this.state?.patientDetails?.full_name}{" "}
                  </Typography.Title>
                  <Typography.Title level={5} className="small-health-id">
                    {" "}
                    Health ID #{this.state?.patientDetails?.health_id}
                  </Typography.Title>
                </>

                <Row style={{ gap: "10px" }} align={"bottom"}>
                  <img
                    src={edit}
                    alt=""
                    style={{ width: "20px", height: "19.961px" }}
                  />
                  <Typography.Title
                    underline
                    className="small-health-edit-profile"
                    data-test-id={"editbuttonId"}
                    onClick={() =>
                      this.props.history.push({
                        pathname: "/PatientUpdate",
                        state: {
                          patientData: this.state?.patientDetails,
                        },
                      })
                    }
                  >
                    Edit profile
                  </Typography.Title>

                  <img
                    src={qrcode}
                    alt=""
                    style={{ width: "34px", height: "34px" }}
                  />
                </Row>
              </Row>
              <Menu
                className="menu-item-list-sibar"
                mode="vertical"
                selectedKeys={[this.state.selectedKey]}
                onClick={this.handleMenuClick}
                data-test-id={"menuId"}
              >
                <Menu.Item
                  key="10"
                  style={{ borderBottom: "1px solid #EAEAEA" }}
                  data-test-id="appointmentId"
                  onClick={this.handleNavigateToAppointment}
                >
                  <div>
                    <Row justify="space-between" className="space-align-block">
                      <Space>
                        <Avatar size="small" src={calendarimg} />
                        <span className="sidebartext">Appointments</span>
                      </Space>
                      <img
                        src={RightOutlined}
                        alt=""
                        style={{ width: "10px" }}
                      />
                    </Row>
                  </div>
                </Menu.Item>
                <Menu.Item
                  key="1"
                  style={{ borderBottom: "1px solid #EAEAEA" }}
                  data-test-id={"healthRecordsId"}
                  onClick={this.handleNavigateToHealthRecords}
                >
                  <div>
                    <Row justify="space-between" className="space-align-block">
                      <Space>
                        <Avatar size="small" src={healthrecords} />
                        <span> Health Records</span>
                      </Space>

                      <img
                        src={RightOutlined}
                        alt=""
                        style={{ width: "10px" }}
                      />
                    </Row>
                  </div>
                </Menu.Item>
                <Menu.Item
                  key="2"
                  style={{ borderBottom: "1px solid #EAEAEA" }}
                  data-test-id={"insuranceId"}
                  onClick={this.handleNavigateToInsurance}
                >
                  <div>
                    <Row justify="space-between" className="space-align-block">
                      <Space>
                        <Avatar size="small" src={Linkinsurance} />
                        <span> Link Insurance</span>
                      </Space>

                      <img
                        src={RightOutlined}
                        alt=""
                        style={{ width: "10px" }}
                      />
                    </Row>
                  </div>
                </Menu.Item>
                <Menu.Item
                  key="3"
                  style={{ borderBottom: "1px solid #EAEAEA" }}
                  data-test-id={"addressId"}
                  onClick={this.handleNavigateToAddress}
                >
                  <div>
                    <Row justify="space-between" className="space-align-block">
                      <Space>
                        <Avatar size="small" src={homaddress} />
                        <span> Manage Address</span>
                      </Space>

                      <img
                        src={RightOutlined}
                        alt=""
                        style={{ width: "10px" }}
                      />
                    </Row>
                  </div>
                </Menu.Item>
                <Menu.Item
                  key="4"
                  style={{ borderBottom: "1px solid #EAEAEA" }}
                  data-test-id={"PaymentId"}
                  onClick={this.handleNavigateToPayment}
                >
                  <div>
                    <Row justify="space-between" className="space-align-block">
                      <Space>
                        <Avatar size="small" src={Paymentmethod} />
                        <span>Payment Methods</span>
                      </Space>

                      <img
                        src={RightOutlined}
                        alt=""
                        style={{ width: "10px" }}
                      />
                    </Row>
                  </div>
                </Menu.Item>
                <Menu.Item
                  key="5"
                  style={{ borderBottom: "1px solid #EAEAEA" }}
                  data-test-id={"settingsId"}
                  onClick={this.handleNavigateToSettings}
                >
                  <div>
                    <Row justify="space-between" className="space-align-block">
                      <Space>
                        <Avatar size="small" src={settings} />
                        <span>Settings</span>
                      </Space>

                      <img
                        src={RightOutlined}
                        alt=""
                        style={{ width: "10px" }}
                      />
                    </Row>
                  </div>
                </Menu.Item>
                <Menu.Item
                  key="6"
                  style={{ borderBottom: "1px solid #EAEAEA" }}
                  data-test-id={"contactUsId"}
                  onClick={this.handleNavigateToContactUs}
                >
                  <div>
                    <Row justify="space-between" className="space-align-block">
                      <Space>
                        <Avatar size="small" src={contactus} />
                        <span>Contact Us</span>
                      </Space>

                      <img
                        src={RightOutlined}
                        alt=""
                        style={{ width: "10px" }}
                      />
                    </Row>
                  </div>
                </Menu.Item>
                <Menu.Item
                  key="7"
                  style={{ borderBottom: "1px solid #EAEAEA" }}
                  data-test-id={"logoutId"}
                  onClick={this.handleLog}
                >
                  <div>
                    <Row justify="space-between" className="space-align-block">
                      <Space onClick={this.handleLogout} >
                        <Avatar size="small" src={logout} />
                        <span>Log out</span>
                      </Space>

                      <img
                        src={RightOutlined}
                        alt=""
                        style={{ width: "10px" }}
                      />
                    </Row>
                  </div>
                </Menu.Item>
                <Menu.Item
                  key="8"
                  style={{ borderBottom: "1px solid #EAEAEA" }}
                  data-test-id={"switchAccountId"}
                  onClick={this.handleNavigateToSwitchAccount}
                >
                  <div>
                    <Row justify="space-between" className="space-align-block">
                      <Space>
                        <Avatar size="small" src={switchaccount} />
                        <span>Switch Account</span>
                      </Space>

                      <img
                        src={RightOutlined}
                        alt=""
                        style={{ width: "10px" }}
                      />
                    </Row>
                  </div>
                </Menu.Item>
              </Menu>
            </div>
          </Layout.Sider>
          <Layout>
            <Layout.Content style={{ margin: "10px 16px" }}>
              {this.props.children}
            </Layout.Content>
          </Layout>
        </Layout>
      </div>
    );
  }

  // Customizable Area End
}

// Customizable Area Start
export default PatientOutLet;
// Customizable Area End
