import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { message as MESSAGE, notification } from "antd";
import * as H from "history";
import type { DatePickerProps } from 'antd';
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    history: H.History;
    location: any;
    // Customizable Area End
}

// Customizable Area Start
export interface ValidResponseType {
    message: object;
    data: object;
}

export interface InvalidResponseType {
    errors: [
        "Record not found."
    ]
}

export interface APIPayloadType {
    contentType?: string;
    method: string;
    endPoint: string;
    body?: object;
    token?: string;
}

export interface AppointmentDataType {
    data: {
        id: string;
        type: string;
        attributes: {
            doctor_id:number;
            health_id: string;
            consultation_category: string;
            consultation_type: string;
            patient_phone_number: string;
            time_slot: string;
            end_time: string;
            mode_type: string;
            patient_full_name: string;
            patient_gender: string;
            patient_address: string;
            patient_weight: string;
            patient_blood_group: string;
            patient_year: string;
            patient_profile_image: string;
            symtoms: string;
            last_visit: string;
            patient_id: number;
            appointment_date: string;
        }
    }
}

export interface DoctorDetailsType {
    data: DoctorDetailsData;
}
export interface DoctorDetailsData {
    id: string,
    type: string,
    attributes: {
        full_name: string,
        medical_representative_name: string,
        representative_contact_no: string,
        profile_image: string
    }
}
export interface PickType {
    value: string
  }
  export interface TimeSlots {
    start_time: "",
    end_time: "",
    is_available: false
  }
  export interface TimeslotsType {
    data: Array<TimeSlots>
  }
// Customizable Area End

interface S {
    // Customizable Area Start
    deleteModal: boolean;
    appointmentId: number;
    appointmentData: AppointmentDataType;
    symptomsData: Array<string>;
    errorRecordFound: boolean;
    showPopup: boolean;
    doctorProfileData: object;
    patientId: number;
    isJoinConsultation: boolean;
    isBeginConsultation: boolean;
    minutes: number;
    seconds: number;
    milliseconds: number;
    isConsultationStart: boolean;
    minutesBefore: string;
    currentDateTime: string;
    isVirtualVideo: string;
    isLoader: boolean;
    contentHeight: number;
    isCategory: string;
    isPhoneConsultation: boolean;
    isRescheduleModal:boolean;
    appointmentDate:string;
      pickValue: Array<PickType>,
      activeTimeZone:string;
      pickTime:string;
        getTimeSlotsData: Array<TimeSlots>;
      timeSlot: string;
      activeTimeSlot:string;
      currentDate:string;
      endTime:string

      
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class AppointmentsDetailsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getAppointmentDetailsCallId: string = "";
    getProfileDetailsCallId: string = "";
    getTimeSlotApiCallId:string="";
    updateappointmentCallId:string=''
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        // Customizable Area End
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            deleteModal: false,
            appointmentData: {
                data: {
                    id: "",
                    type: "",
                    attributes: {
                        doctor_id:0,
                        health_id: "",
                        patient_phone_number: "",
                        time_slot: "",
                        end_time: "",
                        mode_type: "",
                        patient_full_name: "",
                        patient_gender: "",
                        patient_address: "",
                        patient_weight: "",
                        patient_blood_group: "",
                        patient_year: "",
                        patient_profile_image: "",
                        symtoms: "",
                        last_visit: "",
                        patient_id: 0,
                        appointment_date: "",
                        consultation_category: "",
                        consultation_type: "",
                    }
                }
            },
            appointmentId: 0,
            symptomsData: [],
            errorRecordFound: false,
            showPopup: false,
            doctorProfileData: {},
            patientId: 0,
            isJoinConsultation: false,
            isBeginConsultation: false,
            minutes: 0,
            seconds: 0,
            milliseconds: 0,
            isConsultationStart: false,
            minutesBefore: "",
            currentDateTime: "",
            isVirtualVideo: "",
            isLoader: false,
            contentHeight: 0,
            isCategory: "",
            isPhoneConsultation: false,
            isRescheduleModal:false,
            appointmentDate:'',
            pickValue: [
                { value: "Morning" },
                { value: "Afternoon" },
                { value: "Evening" }
              ],
      activeTimeZone: "",
      pickTime:"",
        getTimeSlotsData: [],
      timeSlot: "",
      activeTimeSlot:'',
      currentDate:'',
      endTime:''
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (responseJson.status === 500) {
                MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
                return;
            }
            if (this.isValidResponse(responseJson)) {
                this.apiSuccessCallBacks(apiRequestCallId, responseJson);
            } else if (this.inValidResponse(responseJson)) {
                this.apiFailureCallBacks(apiRequestCallId, responseJson);
            } else if (errorReponse) {
                notification["error"]({
                    message: "Internal Server Error.",
                });
            }
            if(apiRequestCallId === this.updateappointmentCallId){
                this.setUpdateAppointment()
              }
        }
    }

    async componentDidMount() {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
        const rootElement = document.getElementById('root');
        if (rootElement) {
            this.setState({ contentHeight: rootElement.offsetHeight+33});
        }
        this.setState({ appointmentId: this.props.location.state.appointmentId }, () => {
            this.getAppointmentDetailsfunction();
        });
        this.getProfileDetailsfunction();
        this.getCurrentTimeHHMM();
         this.setState({currentDate :moment().format("DD-MM-YYYY") })
    }

    apiCall = (data: APIPayloadType) => {
        let { contentType, method, endPoint, body } = data;
        let token = localStorage.getItem("token");
        const header = {
            "Content-Type": contentType,
            token
        };
        let requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        body &&
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    }

    isValidResponse = (responseJson: ValidResponseType) => {
        return (responseJson && responseJson.data);
    };

    inValidResponse = (responseJson: InvalidResponseType) => {
        return responseJson && responseJson.errors;
    };

    apiSuccessCallBacks = (apiRequestCallId: string, responseJson: AppointmentDataType & DoctorDetailsType & TimeslotsType) => {
        if (apiRequestCallId === this.getAppointmentDetailsCallId) {
            this.getAppointmentDetailsSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.getProfileDetailsCallId) {
            this.getProfileDetailsSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.getTimeSlotApiCallId) {
            this.getTimeSlotSuccessCallBack(responseJson);
          }
          
    };

    apiFailureCallBacks = (apiRequestCallId: string, responseJson: InvalidResponseType) => {
        if (apiRequestCallId === this.getAppointmentDetailsCallId) {
            this.getAppointmentDetailsFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.getTimeSlotApiCallId) {
            this.getTimeSlotFailureCallBack(responseJson);
          }
    };
    setUpdateAppointment = () => {
        this.setState({isRescheduleModal:false});
        this.props.history.push('/doctorSchedule')
    }
    getAppointmentDetailsfunction = async () => {
        this.getAppointmentDetailsCallId = this.apiCall({
            contentType: configJSON.getAppointmentDetailsContentType,
            method: configJSON.getAppointmentMethod,
            endPoint: `${configJSON.getAppointmentDetailsEndPoint}?appointment_id=${this.state.appointmentId}`
        });
        this.setState({isLoader: true});
    };
    getTimeSlotFailureCallBack = (err: any) => {
        if (err.errors[0].message) {
          this.setState({
            getTimeSlotsData: [],
          })
          notification["error"]({
            message: err.errors[0].message
          });
        }
      }
 getTimeSlotSuccessCallBack = (res: TimeslotsType) => {
    this.setState({
      getTimeSlotsData: res.data,
      pickTime: "",
    })
  }
    getAppointmentDetailsSuccessCallBack = (response: AppointmentDataType) => {
        this.setState({
            appointmentData: response,
            isVirtualVideo: response.data.attributes.consultation_type,
            isLoader: false,
            isCategory: response.data.attributes.consultation_category
        }, () => {
            this.subtractTenMinutes();
        })
        let parsedData: string[] = []
        let tempData: Array<string> = JSON.parse(response.data.attributes.symtoms);
        tempData?.forEach((symtoms: string) => {
            parsedData.push(symtoms)
        })

        this.setState({
            symptomsData: parsedData,
            patientId: response.data.attributes.patient_id
        });
        localStorage.setItem("patient_health",response.data.attributes.health_id)
    };

    getAppointmentDetailsFailureCallBack = async (error: InvalidResponseType) => {
        this.setState({
            errorRecordFound: true,
            isLoader: false
        });
    };

    getProfileDetailsfunction = async () => {
        this.getProfileDetailsCallId = this.apiCall({
            contentType: configJSON.getProfileDetailsContentType,
            method: configJSON.getProfileDetailsMethod,
            endPoint: `${configJSON.getProfileDetailsEndPoint}`
        });
    };

    getProfileDetailsSuccessCallBack = (response: DoctorDetailsType) => {
        this.setState({
            doctorProfileData: response
        });
    };

    handelDoctorProfilePopupShowFunction = () => {
        this.setState({ showPopup: !this.state.showPopup });
    };

    handleDoctorLogout = () => {
        localStorage.clear();
        notification["success"]({
            message: "Logout successfully",
        });
        this.props.history.push("/");
    };

    handleDoctorDetailsPage = () => {
        this.props.history.push("/doctorprofiledetails");
    };

    handleEditDoctorProfile = () => {
        this.props.history.push("/editdoctordetails")
    };

    handleOpenDeleteModal = () => {
        this.setState({ deleteModal: false });
    };

    handleOpenModal = () => {
        this.setState({ deleteModal: true });
    };

    handleCloseDeleteModal = () => {
        this.setState({ deleteModal: false });
    };

    handleConsultation = (id: number, patientid: number) => {
        this.props.history.push({
            pathname:"/viewprescription",
            state: {appointments_id: id, patient_Id: patientid}
        });
    };

    handleNavigate = (id:number,patientid: number) => {
        this.props.history.push({
            pathname:"/viewprescription",
            state: {appointments_id: id, patient_Id: patientid}
        });
    };

    navigateInvoicePage = (id:number) => {
        this.props.history.push({
            pathname:"/invoicedetails",
            state: {appointments_id: id}
        });
    };

    handlePopupClose = () => {
        this.setState({
            showPopup: false
        });
    };

    handleMedicalHistory = (id: number , apoointId: number) => {
        this.props.history.push({
            pathname:"/viewprescription",
            state: {
                patient_Id: id,
                appointments_id: apoointId,
                medicalHistoryTab: "Case"
            }
        });
    };

    navigateToVideoCallScreen=()=>{
        this.props.history.push({
            pathname:"/videoscreen",
            state: {
                item:this.state.appointmentData
            }
        });
    }

    navigateToViewPrescriptionScreen=()=>{
        this.props.history.push({
            pathname:"/viewPrescriptionScreen",
            state: {
                item:this.state.appointmentData
            }
        });
    }

    navigateToAddPrescriptionScreen=()=>{
        this.props.history.push({
            pathname:"/AddPrescriptionScreen",
            state: {
                patient_Id:this.state.patientId,
                appointments_id: this.state.appointmentId,
            }
        });
    };

    handleJoinConsultationModal = () => {
        this.setState({ isJoinConsultation: false });
    };

    handleJoinModal = () => {
        this.setState({ isJoinConsultation: true } );
    };

    handleConsultationBeginModal = () => {
        this.setState({ isBeginConsultation: false });
    };

    handlePhoneConsultation = () => {
        this.setState({ 
            isPhoneConsultation: true,
            isJoinConsultation: false
        });
    };

    handleCancelPhoneConsultation = () => {
        this.setState({ isPhoneConsultation: false });
    };

    handleBeginConsultation = () => {
        this.setState({
            isBeginConsultation: true,
            isJoinConsultation: false
        });
        const timerInterval = setInterval(() => {
            let current = (new Date()).getTime()
            let appointment = ((new Date(this.state.minutesBefore)).getTime()) + (10*60*1000)
            let remainingMinute = appointment-current;
            const currentSec = (new Date()).getSeconds();
            if (remainingMinute < 0 ) {
                clearInterval(timerInterval);
                this.navigateToVideoCallScreen();
            } else {
                this.setState({
                    minutes: Math.floor((Math.floor(remainingMinute/1000)%3600)/60),
                    seconds: 60 - currentSec
                });
            }
        }, 1000);
    };

    subtractTenMinutes = () => {
        let date = new Date(this.state.appointmentData.data.attributes.appointment_date).toDateString();
        let times = this.state.appointmentData.data.attributes.time_slot;
        let parts = times.split(" ");
        let timeComponents = parts[0].split(":");
        let hours = parseInt(timeComponents[0]);
        let minutes = parseInt(timeComponents[1]);
        let period = parts[1];
        if (period === "PM" && hours < 12) {
            hours += 12;
        } else if (period === "AM" && hours === 12) {
            hours = 0;
        }
        let totalMinutes = hours * 60 + minutes;
        totalMinutes -= 10;
        totalMinutes = Math.max(totalMinutes, 0);
        let newHours = Math.floor(totalMinutes / 60);
        let newMinutes = totalMinutes % 60;
        let newTime: string|Date = new Date( date + "-" +("0" + newHours).slice(-2) + ":" + ("0" + newMinutes).slice(-2))
        let currentDateTime = new Date(this.state.currentDateTime)
        if (currentDateTime >= newTime) {
            this.setState({
                isConsultationStart: true,
                minutesBefore: date + "-" +("0" + newHours).slice(-2) + ":" + ("0" + newMinutes).slice(-2)
            });
        } else {
            notification["error"]({
                message: "Please wait for start consultation",
            });
        }
    };

    getCurrentTimeHHMM = () => {
        let current: Date = new Date();
        let hours : number | string = current.getHours();
        let minutes : number | string = current.getMinutes();
        hours = ("0" + hours).slice(-2);
        minutes = ("0" + minutes).slice(-2);
        let currentTimeHHMM = current.toDateString() + "-" + hours + ":" + minutes;
        return this.setState({currentDateTime : currentTimeHHMM});
    };
    handleReschedule = () => {
        this.setState({isRescheduleModal:true})
    }
    handleCloseRescheduleModal = () => {
        this.setState({isRescheduleModal:false, activeTimeSlot:"", pickTime:"", activeTimeZone:'', getTimeSlotsData:[]})
    }
    handleScheduleDate: DatePickerProps['onChange'] = (date, dateString) => {
        this.setState({
            currentDate: moment(dateString).format("DD-MM-YYYY")
        })
      };
       disabledPastDate = (current: any) => {
           return current && current <= moment().startOf("day");
         }
   getTimeSlotShow = async (item: string) => {
    const doctorId=this.state.appointmentData.data.attributes.doctor_id
    const modeType=this.state.appointmentData.data.attributes.mode_type
            this.setState({
              pickTime: item,
              activeTimeZone: item
            }, () => {
              this.getTimeSlotApiCallId = this.apiCall({
                contentType: configJSON.getAppointmentDetailsContentType,
                method: configJSON.getApiMethod,
                endPoint: `${configJSON.getTimeSlotEndpoint}?[data][attributes][appointment_date]=${this.state.currentDate}&[data][attributes][mode_type]=${modeType}&[data][attributes][time_slot]=${this.state.pickTime}&[data][attributes][doctor_id]=${doctorId}&[data][attributes][user_type]=doctor`
              });
            })
          };
          handleScheduleTime = (value: string, endTime:string) => {
            this.setState({
              timeSlot: value,
              activeTimeSlot: value,
              endTime:endTime
            })
          }
          handleRescheduleAppointmentApi=()=>{
    const modeType=this.state.appointmentData.data.attributes.mode_type
            const {timeSlot, endTime,currentDate, activeTimeZone, appointmentData}= this.state
              const header = {
                "token":localStorage.getItem("token")
              };
              const formdata = new FormData()
              formdata.append("appointment_date", currentDate);
              formdata.append("pick_time", activeTimeZone);
              formdata.append("start_time", timeSlot);
              formdata.append("end_time", endTime);
              formdata.append("mode_type",modeType);
              const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
              );
              this.updateappointmentCallId = requestMessage.messageId;
              requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `/bx_block_appointment_management/reschedule_appointment?id=${appointmentData.data.id}`
              );
              requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
              );
              requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.putPrescriptionMedicineMethod
              );
              requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                formdata
              );
              runEngine.sendMessage(requestMessage.id, requestMessage);
              return true;
          }
    // Customizable Area End
}
